<template>
  <div class="chart-widget">
    <h5 class="widget-title">Chart Widget</h5>
    <!-- Placeholder for chart -->
    <div class="chart-placeholder">Chart will be here</div>
  </div>
</template>

<script>
export default {
  name: 'ChartWidget',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.chart-widget {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.widget-title {
  font-size: 1.25rem;
  color: #333;
}

.chart-placeholder {
  height: 150px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
}
</style>
